import React, { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo';
import { GET_CART_JOURNEYS } from './queries';
import { SET_STATUS, GET_STATUS } from '../global/queries';
import LineItem from './LineItem';
import Button from '../global/Button';
import { Link } from 'gatsby';
import Loader from '../global/Loader';

const EmptyCart = () => (
  <p className="text-center text-gray-300 text-2xl pb-16">Your cart is empty.</p>
)

const CartItems = ({ cart, client }) => {
  const [loading, setLoading] = useState(true)
  const [journeys, setJourneys] = useState(null)

  useEffect(() => {
    getCartItemDetails()
  }, [])

  const getCartItemDetails = async () => {
    setLoading(true)
    const { data, errors } = await client.query({
      query: GET_CART_JOURNEYS,
      variables: {
        ids: cart.items.map(({ id }) => id)
      }
    })
    
    if (!data.errors) {
      setJourneys(data.journeys)
      setLoading(false)
    } else {
      client.mutate({
        mutation: SET_STATUS,
        variables: {
          __typename: 'Status',
          code: 'ERROR',
          message: errors[0].message
        },
        refetchQueries: [{
          query: GET_STATUS
        }]
      })
      setLoading(false)
    }
  }

  if (loading) return <Loader />
  if (!cart || cart.items.length === 0) return <EmptyCart />
  
  return (
    <div className="mx-auto md:w-4/5 lg:w-3/5">
      {cart.items.map((item, index) => {
        const { node } = journeys.edges.find(({ node }) => node.id === item.id)
        return <LineItem item={item} index={index} journey={node} key={`item-${index}`} />
      })}
      <div className="flex justify-between sm:justify-end my-8">
        <span className="mr-16 font-bold">Total Cost:</span>
        <span>{`£${cart.subtotal && cart.subtotal.toFixed(2)}`}</span>
      </div>
      <div className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center">
        <Button to="/checkout" className="btn btn-green mb-12 sm:mb-0 block text-center sm:inline-block">
          Proceed to Checkout
        </Button>
        <Link to="/" className="no-underline text-green-500 font-bold w-full lg:w-1/2">← View More Tours</Link>
      </div>
    </div>
  )
}

export default withApollo(CartItems)