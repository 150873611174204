import React, { useEffect } from 'react'
import { withApollo } from 'react-apollo';

import SEO from '../components/global/SEO'
import Layout from '../components/Layout'
import { Section } from '../components/global/Section'
import MainCart from '../components/cart/MainCart'
import { useStatus } from '../components/global/customHooks';

const CartPage = ({ location }) => {
  const { setSuccess, setError } = useStatus()  

  useEffect(() => {
    if (location.state && location.state.status) {
      (location.state.status.code === 'ERROR') && setError(location.state.status.message)
      (location.state.status.code === 'SUCCESS') && setSuccess(location.state.status.message)
    }
  }, [location])
  
  return (
    <Layout>
      <SEO 
        title="Cart • Coach Tours, Giants Causeway, Belfast • Tours Ireland"
      />
      <Section>
        <MainCart />
      </Section>
    </Layout>
  )
}

export default withApollo(CartPage)