import React from 'react'
import { useQuery } from 'react-apollo';

import { GET_CART } from './queries'

import { H2 } from '../global/Headings';
import { hasExpiredCartItems } from '../../utils/helpers';
import CartItems from './CartItems';

const MainCart = () => {
  const { data, client } = useQuery(GET_CART)

  if (!hasExpiredCartItems(data.cart, client)) {
    return (
      <>
        <H2 icon>Shopping Cart</H2>
        <CartItems cart={data.cart} />
      </>
    )
  }
  return null
}

export default MainCart