import React, { useEffect } from 'react'
import { withApollo } from 'react-apollo'
import { Formik, Form, Field, ErrorMessage, FieldArray, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { UPDATE_CART_ITEM, GET_CART, DELETE_CART_ITEM } from './queries'
import { formatDate } from '../../utils/helpers'
import { useStatus } from '../global/customHooks';

const ErrorWrapper = ({ children }) => {
  if (typeof children === 'string') {
    return (
      <div className="w-full my-2 text-xs block bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded-sm relative" role="alert">
        {children}
      </div>
    )
  }
  return null
}

const Ticket = ({ ticket, index, max, onChangeHandler }) => (
  <>
    <ErrorMessage name={`tickets[${index}].quantity`} component={ErrorWrapper} />
    <div className="py-2 flex flex-wrap items-center">
      <label className="block sm:inline text-gray-500 text-xs md:text-sm">{ticket.title} ({`£${ticket.cost.toFixed(2)}`}): </label>
      <div className="ml-auto">
        <div className="flex items-center">
          <Field name={`tickets[${index}].quantity`}>
            {({ _, form: { setFieldValue, setFieldTouched } }) => (
              <button type="button" className="inline-block text-green-500 w-6 text-lg" onClick={() => {
                  onChangeHandler(parseInt(ticket.quantity - 1), index)
                  if (ticket.quantity > 0) {
                    setFieldValue(`tickets[${index}].quantity`, parseInt(ticket.quantity) - 1);
                    setFieldTouched(`tickets[${index}].quantity`);
                  }
                }}
              >
                –
              </button>
            )}
          </Field>
          <Field name={`tickets[${index}].quantity`}>
            {({ field, form: { setFieldValue, setFieldTouched } }) => (
              <input
                {...field}
                type="number"
                min="0"
                max={max}
                value={!isNaN(field.value) ? field.value : "" }
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setFieldValue(`tickets[${index}].quantity`, parseInt(e.target.value));
                    setFieldTouched(`tickets[${index}].quantity`);
                    onChangeHandler(parseInt(e.target.value), index)
                  }
                }}
                className="counter appearance-none w-8 p-1 text-center text-xs md:text-sm mx-1 rounded-sm border border-gray-200"
              />
            )}
          </Field>
          <Field name={`tickets[${index}].quantity`}>
            {({ _, form: { setFieldValue, setFieldTouched } }) => (
              <button type="button" className="inline-block text-green-500 w-6 text-lg"
                onClick={() => {
                  if (isNaN(ticket.quantity)) {
                    ticket.quantity = 0
                  }
                  if (ticket.quantity >= 0) {
                    setFieldValue(`tickets[${index}].quantity`, parseInt(ticket.quantity) + 1);
                    setFieldTouched(`tickets[${index}].quantity`);
                    onChangeHandler(parseInt(ticket.quantity + 1), index)
                  }
                }}>
                +
              </button>
            )}
          </Field>
        </div>
      </div>
    </div>
  </>
)

const Tickets = ({ submitForm, tickets, max, onChangeHandler }) => {
  useEffect(() => {
    submitForm()
  }, [])
  if (tickets.length === 0) return <h4 className="mt-4 text-center text-gray-400 normal-case text-base">No Tickets Available</h4>
  return (
    <FieldArray
      name="tickets"
      render={() => (
        <>
          <ErrorMessage name={`tickets`} component={ErrorWrapper} />
          {tickets.map((ticket, index) => (
            <Ticket key={`tickets[${index}]`} ticket={ticket} index={index} max={max} onChangeHandler={(count, index) => onChangeHandler(count, index)} />
          ))}
        </>
      )}
    />
  )
}

const LineItem = ({ item, index, journey, client }) => {
  const { setSuccess } = useStatus()
  
  const updateCount = (count, ticketIndex) => {
    client.mutate({
      mutation: UPDATE_CART_ITEM,
      variables: {
        quantity: count,
        item: index,
        ticket: ticketIndex
      },
      refetchQueries: [{
        query: GET_CART
      }]
    })
  }

  const deleteCartItem = (itemIndex) => {
    client.mutate({
      mutation: DELETE_CART_ITEM,
      variables: {
        item: itemIndex
      },
      refetchQueries: [{
        query: GET_CART
      }]
    })
    setSuccess('Tickets successfully removed from cart.')
  }

  const schema = Yup.object().shape({
    tickets: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
            .typeError('Ticket quantity must be a number')
            .min(0, 'Ticket quantity must be greater than ${min}.')
            .required('Required')
        })
      )
      .test({
        name: 'max',
        test: values => {
          const count = values.reduce((count, t) => {
            if (t.quantity) {
              return count + t.quantity
            }
            return count + 0
          }, 0)
          return (journey.seatsRemaining - count) >= 0
        },
        message: 'Not enough seats remaining.'
      })
      .test({
        name: 'min',
        test: values => {
          const count = values.reduce((count, t) => {
            if (t.quantity) {
              return count + t.quantity
            }
            return count + 0
          }, 0)
          return count >= 1
        },
        message: 'Minimum of 1 ticket required.'
      })
  })

  return (
    <div className="flex bg-white rounded-sm p-6 shadow w-full relative mb-6">
      <img className="h-16 w-16 md:h-24 md:w-24 rounded-sm mr-4" src={`${journey.trip.image}?w=200`} />
      <div className="flex-auto">
        <h2 className="text-base md:text-lg mb-1 pr-24">{journey.trip.title}</h2>
        <div className="text-sm md:text-base text-gray-500">{formatDate(journey.date)}</div>
        <Formik
          initialValues={{ tickets: item.tickets }}
          validationSchema={schema}
          onSubmit={() => {}}
        >
          {({ submitForm, values }) => (
            <Form>
              <div className="border-t mt-4 pt-2 border-gray-100">
                <Tickets submitForm={submitForm} tickets={values.tickets} max={journey.seatsRemaining} onChangeHandler={updateCount} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <span className="m-6 text-sm md:text-base cursor-pointer absolute top-0 right-0 text-red-600 hover:text-red-700 flex items-center justify-center" onClick={() => deleteCartItem(index) }>
        Remove <FontAwesomeIcon icon={faTimesCircle} className="ml-2 text-base md:text-lg" />
      </span>
    </div>
  )
}

export default withApollo(LineItem)